import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Ehdot = () => (
  <Layout>
    <Seo title="LÖFBERGS – KILPAILUN SÄÄNNÖT" />
    <h1>Kilpailu on päättynyt</h1>
    {/* <div className="kilpailunSaannot" id="1">
      <h1>LÖFBERGS – KILPAILUN SÄÄNNÖT</h1>
      <p></p>
      <h3>JÄRJESTÄJÄ</h3>
      <p>
        Löfbergs Finland Oy <br />
        c/o Ekova Tilitoimisto Oy <br />
        Toritie 1 <br />
        65610 Mustasaari
        <br />
        Puh: 
        <a href="tel:004654140100" target="_blank" rel="noreferrer noopener">
          +46 54 14 01 00
          <br />
        </a>
        Y-tunnus 2905776-4
      </p>
      <h3>OSALLISTUMISAIKA JA OSALLISTUMINEN</h3>
      <p>
        Kilpailu järjestetään 1.–31.3.2022. Kilpailuajan ulkopuolella tulleita
        osallistumisia ei oteta huomioon. Arvontaan osallistutaan tekemällä
        testi sekä täyttämällä ja lähettämällä yhteystiedot verkkopalvelussa{" "}
        <a href="https://loydavahvuutesi.lofbergs.fi">
          {" "}
          https://loydavahvuutesi.lofbergs.fi
        </a>
        . 
        <br />
        Yhteystietonsa jättämällä osallistuja hyväksyy
        kilpailusäännöt. Kilpailuun voivat osallistua kaikki Suomessa asuvat
        täysi-ikäiset luonnolliset henkilöt. 
      </p>
      <h3>VOITTAJIEN VALINTA</h3>
      <p>
        Voittaja arvotaan yhteystietonsa jättäneistä osallistujista pe 8.4.2022
        mennessä. Voittajaan otetaan yhteyttä henkilökohtaisesti. Mikäli
        voittajaa ei tavoiteta seitsemän (7) vuorokauden kuluessa arvonnasta,
        arvotaan uusi voittaja. 
      </p>
      <h3>PALKINTO</h3>
      <p>
        Palkinto sisältää Stanley Classic -lounasboksin, Stanley Classic
        -termospullon sekä 12 pakettia Löfbergs Inferno -suodatinkahvia.
        Palkinnon arvo on n. 205 €. Palkintoa ei voi vaihtaa eikä muuttaa
        rahaksi. Palkinto lähetetään postitse voittajan ilmoittamaan
        osoitteeseen. 
      </p>
      <h3>ARPAJAISVERO</h3>
      <p>Palkintojen lakisääteisestä arpajaisverosta vastaa järjestäjä.</p>
      <h3>JÄRJESTÄJÄN VASTUU</h3>
      <p>
        Palkinnon vastaanottaja vapauttaa Järjestäjän, arvonnan toteuttajan,
        arvonnan yhteistyökumppanit, osallistuvat vähittäismyyntipaikat sekä
        edellä mainittujen yhteistyökumppanit vastuusta, joka koskee vammaa tai
        vahinkoa, joka aiheutuu tai jonka väitetään aiheutuneen osallistumisesta
        tähän arvontaan tai palkinnon vastaanottamiseen tai käyttämiseen.
        Arvonnan järjestäjän vastuu osallistujia kohtaan ei voi missään
        tilanteessa ylittää näissä säännöissä mainittujen palkintojen arvoa tai
        määrää.
        <br />
        <br />
        Järjestäjä, arvonnan toteuttaja tai arvonnan yhteistyökumppanit eivät
        vastaa mahdollisista painovirheistä, osallistujien yhteystiedoissa
        olevista virheistä taikka tietoteknisistä syistä johtuvista esteistä
        arvontaan osallistumisessa, palkinnon vastaanottamisessa tai sen
        käyttämisessä.
        <br />
        <br />
        Edellä mainitut vastuunrajoitukset eivät rajoita sellaista
        korvausvelvollisuutta, jota ei voida poissulkea tai rajoittaa
        kuluttajansuojalainsäädännön, tuotevastuulain tai muun pakottavan
        lainsäädännön nojalla.{" "}
      </p>
      <h3>SÄÄNTÖJEN MUUTOKSET JA TULKINTA:</h3>{" "}
      <p>
        Järjestäjä pidättää itsellään oikeuden sääntömuutoksiin. Mahdollisista
        sääntömuutoksista ilmoitetaan arvontaan liittyvillä verkkosivuilla.
        Järjestäjä voi myös oikaista tai muuttaa sääntöjä mahdollisten paino- ja
        muiden virheiden korjaamiseksi arvontaan osallistumisajan aikana.
        Arvontasääntöjen soveltamiseen ja tulkintaan liittyvät mahdolliset
        epäselvyydet ratkaisee Järjestäjä.
      </p>
      <h3>HENKILÖTIEDOT</h3>
      <p>
        Arvontaan osallistuneiden henkilötietoja ei käytetä suoramarkkinointiin.
        Tietoja ei luovuteta kolmansien osapuolien käyttöön. Löfbergs pidättää
        oikeuden julkaista voittajan nimen Internet-, Instagram- ja
        Facebook-sivuillaan.
      </p>
      <p>Järjestäjä pidättää oikeuden muuttaa näitä sääntöjä.</p>
      <h3>HENKILÖTIETOJEN KÄYTÖSTÄ</h3>
      <h4>1. Tietojen kerääjä</h4>
      <p>Löfbergs Finland Oy</p>
      <p>c/o Ekova Tilitoimisto Oy</p>
      <p>Toritie 1</p>
      <p>
        65610 Mustasaari
        <br />
        Puh: 
        <a href="tel:004654140100" target="_blank" rel="noreferrer noopener">
          +46 54 14 01 00
          <br />
        </a>
        Y-tunnus 2905776-4
      </p>
      <h4>2. Rekisterin nimi</h4>
      <p>Löfbergs kilpailurekisteri</p>
      <h4>3. Tietojen käsittelyn tarkoitus</h4>
      <p>
        Lomakkeesta saatujen tietojen avulla otetaan yhteys kilpailun
        voittajaan. Yhteystietoja ei käytetä tämän jälkeen, vaan ne poistetaan,
        ja kilpailun päätyttyä rekisteri tuhotaan.
      </p>
      <h4>4. Rekisterin tietosisältö</h4>
      <p>Vastaanottajan nimi, sähköpostiosoite ja puhelinnumero.</p>
      <h4>5. Säännönmukaiset tietolähteet</h4>
      <p>Asiakkaan oma ilmoitus kilpailusivustolla.</p>
      <h4>6. Tietojen säännönmukaiset luovutukset</h4>
      <p>
        Tietoja ei luovuteta kolmansille osapuolille. Tietoja ei käytetä muita
        käyttötarkoituksia varten. Tiedot poistetaan, kun kilpailu on päättynyt.
      </p>
      <h4>7. Tietojen siirto EU:n tai ETAn ulkopuolelle</h4>
      <p>Asiakasrekisterin tietoja ei siirretä EU/ETA-alueen ulkopuolelle.</p>
      <h4>8. Rekisteröidyn oikeudet</h4>
      <h5>a. Tarkastus- ja oikaisuoikeus</h5>
      <p>
        Rekisteröidyllä on oikeus tarkistaa häntä koskevat rekisteriin
        tallennetut tiedot. Tarkastusoikeutta koskevat yhteydenotot tulee tehdä
        kirjallisina ja allekirjoitettuina kohdassa 1. mainittuun osoitteeseen.
      </p>
      <h5>b. Kielto-oikeus</h5>
      <p>
        Rekisteröidyllä on oikeus kieltää rekisterinpitäjää käsittelemästä häntä
        itseään koskevia tietoja.
      </p>
      <p>
        Kielto- ja oikaisuasioissa rekisteröity voi olla yhteydessä puhelimitse
        tai postitse kohdassa 1. mainittuun osoitteeseen
      </p>
      <h4>9. Rekisterin suojauksen periaatteet</h4>
      <h5>a. Manuaalinen aineisto</h5>
      <p>Manuaalista aineistoa ei ole.</p>
      <h5>b. Sähköisesti tallennetut tiedot</h5>
      <p>
        Asiakastietoja sisältävän järjestelmän käyttöön ovat oikeutettuja vain
        ne työntekijät, joilla työnsä puolesta on oikeus käsitellä
        asiakastietoja. Kullakin käyttäjällä on oma käyttäjätunnus ja salasana
        järjestelmään. Työtiloissa liikkumista valvotaan kulkuluvilla. Tiedot
        kerätään tietokantoihin, jotka ovat palomuurein, salasanoin ja muilla
        teknisillä keinoilla suojattuja. Tietokannat ja niiden varmuuskopiot
        sijaitsevat lukituissa tiloissa ja tietoihin pääsevät käsiksi vain
        tietyt ennalta nimetyt henkilöt. Tiedot poistetaan kilpailun päätyttyä,
        ja rekisteri tuhotaan kilpailun päätyttyä.
      </p>
    </div> */}
  </Layout>
)

export default Ehdot
